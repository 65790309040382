import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Ojén is a little mountain village in the back country of Marbella at the
Costa del Sol. The name gives a hint about the weather qualities. Lots of
sun and temperatures between 10 and 20 degrees in the winter time make the
south of Andalucía a great alternative to the snowy alps.`}</p>
    <TrailguidePlugin content="lat=36.5716&lng=-4.8477" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=14,36.5716,-4.8477"
        }}>{`web-app`}</a></p>
    </p>
    <h4>{`Open the region around Ojén directly on `}<a parentName="h4" {...{
        "href": "https://trailguide.net/?pos=14,36.5716,-4.8477&map=cyclemap"
      }}>{`Trailguide`}</a>{`.`}</h4>
    <br />
    <p>{`The area around Ojén is split in two characteristically different sides.
Roots – you rarely see in southern Spain - sandy, soft forest floor and
compact rocks you find on the green west side. Loose rocks and steep or
technical trails on the brown east side. There is a strong mountain bike
community and since a couple of years there are events like the Spanish
Enduro Open, or the Endurama Sun Race.`}</p>
    <br />
    <br />
    <h4>{`Arroyo Tejar Rockgarden`}</h4>
    <p>{`Ojen with its white buildings crouches at the mountain. Tanja Naber
is chasing the flow on this challenging rocky trail.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2624"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/ojen_7.jpg" mdxType="Image" />
    <br />
    <h4>{`Juanar hacia Ojen`}</h4>
    <p>{`On the west side, you find this gem of a trail. A great mix of flowy
and technical sections, also nice sandy parts and soft forest floor with
roots in the upper part. The lower part becomes more open and faster.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2630"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/ojen_1.jpg" mdxType="Image" />
    <br />
    <h4>{`Tramo 3`}</h4>
    <p>{`There are also easier, flowy trails, winding in a great rhythm
through the sandy pine forest.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2631"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/ojen_2.jpg" mdxType="Image" />
    <br />
    <h4>{`Canterra`}</h4>
    <p>{`The Canterra trail offers some nice steep parts into loose catch-berms
and flowy traverses.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2632"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/ojen_3.jpg" mdxType="Image" />
    <br />
    <h4>{`Las Antennas`}</h4>
    <p>{`On the east side of Ojén the trails change character. The terrain is dry, rocky
and loose. Without the trees you have a nice view on the coast towards Marbella.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2622"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/ojen_5.jpg" mdxType="Image" />
    <br />
    <p>{`This descent starts high up and you can connect several
trails to ride back down to Ojén.`}</p>
    <Image src="destinations/ojen_4.jpg" mdxType="Image" />
    <br />
    <h4>{`La Alberca`}</h4>
    <p>{`Flowers in december? Only in the south of Spain.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2623"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/ojen_6.jpg" mdxType="Image" />
    <br />
    <p>{`The best time of the year for biking is September to May. In the winter
month it can be wet, but temperatures rarely drop below 10 degrees and the
sandy terrain absorbs a little rain very well.`}</p>
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      